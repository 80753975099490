<template>
  <div class="person">
    <div class="row">
      <!-- 实时数据 -->
      <div class="r1-c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">实时数据</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div
                style="
                  width: 100%;
                  height: 25%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div class="r1-c1-span">
                  <div class="r1-c1-span-name">进场<br />人数</div>
                  <div class="r1-c1-span-value" v-if="realData">
                    {{ realData.arenaNumber }}
                  </div>
                </div>
                <div class="r1-c1-span">
                  <div class="r1-c1-span-name">现场<br />人数</div>
                  <div class="r1-c1-span-value" v-if="realData">
                    {{ realData.localeNumber }}
                  </div>
                </div>
                <div class="r1-c1-span">
                  <div class="r1-c1-span-name">出场<br />人数</div>
                  <div class="r1-c1-span-value" v-if="realData">
                    {{ realData.outNumber }}
                  </div>
                </div>
              </div>
              <div
                id="echarts-person-r1-c1"
                style="width: 100%; height: 75%"
              ></div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="r1-c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">单位人数统计</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="flex-row" style="width: 100%; height: 100%">
                <div class="flex-col" style="width: 30%; height: 100%;">
                  <div
                    id="echarts-person-r1-c2-1"
                    style="width: 100%; height: 75%; position: relative;"
                  >
                    <div class="number-bg-wrap">
                      <img class="number-bg" src="../assets/person/jianshe.png">
                    </div>
                    <p class="number-value" style="color: #00dbf3;">{{ unitData ? unitData.construction : 0 }}</p>
                  </div>
                  <div class="echarts-person-r1-c2-2-title">建设单位人数</div>
                </div>
                <div class="flex-col" style="width: 30%; height: 100%">
                  <div
                    id="echarts-person-r1-c2-2"
                    style="width: 100%; height: 75%; position: relative;"
                  >
                    <div class="number-bg-wrap">
                      <img class="number-bg" src="../assets/person/shigong.png">
                    </div>
                    <p class="number-value" style="color: #fcfb5b;">{{ unitData ? unitData.contract : 0 }}</p>
                  </div>
                  <div class="echarts-person-r1-c2-2-title">施工单位人数</div>
                </div>
                <div class="flex-col" style="width: 30%; height: 100%">
                  <div
                    id="echarts-person-r1-c2-3"
                    style="width: 100%; height: 75%; position: relative;"
                  >
                    <div class="number-bg-wrap">
                      <img class="number-bg" src="../assets/person/jianli.png">
                    </div>
                    <p class="number-value" style="color: #fe9c45;">{{ unitData ? unitData.supervisor : 0 }}</p>
                  </div>
                  <div class="echarts-person-r1-c2-2-title">监理单位人数</div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二行 -->
    <div class="row">
      <!-- 年龄分布 -->
      <div class="r2-c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">工种分布</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div
                id="echarts-person-r2-c1"
                style="
                  width: 100%;
                  height: 100%;
                  background-color: rgba(255, 255, 255, 0);
                "
              ></div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 工种分布 -->
      <div class="r2-c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">年龄分布</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div
                id="echarts-person-r2-c2"
                style="
                  width: 100%;
                  height: 100%;
                  background-color: rgba(255, 255, 255, 0);
                "
              ></div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 类型分布 -->
      <div class="r2-c3">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">类型分布</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div
                id="echarts-person-r2-c3"
                style="
                  width: 100%;
                  height: 100%;
                  background-color: rgba(255, 255, 255, 0);
                "
              ></div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.number-value {
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 42 / @Width;
  font-weight: 700;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50 / @Width;
}
.number-bg-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  padding: 0;
    display: flex;
  justify-content: center;
  align-items: center;
}
.number-bg {
  width: 100%;
}

@Width: 192rem;
.row-height-1,
.row-height-2 {
  height: 335 / @Width;
}
.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.echarts-person-r1-c2-2-title {
  width: 100%;
  height: 25%;
  text-align: center;
  line-height: 80 / @Width;
  font-size: 24 / @Width;
}
.r1-c1-span {
  width: 195 / @Width;
  height: 90 / @Width;
  background-image: url("../assets/person/r1c1-item-bg.png");
  filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  margin: 0 60 / @Width;
  display: flex;
  .r1-c1-span-name {
    width: 80 / @Width;
    height: 90 / @Width;
    font-size: 20 / @Width;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .r1-c1-span-value {
    width: 115 / @Width;
    height: 90 / @Width;
    font-size: 26 / @Width;
    font-weight: 700;
    text-align: center;
    line-height: 90 / @Width;
    color: #ffbe22;
  }
}
.person {
  width: 100%;
  height: 100%;
  // background-color: #fff;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20 / @Width;
  }
  .r1-c1 {
    width: 1100 / @Width;
    height: 410 / @Width;
  }
  .r1-c2 {
    width: 715 / @Width;
    height: 410 / @Width;
  }

  .r2-c1,
  .r2-c2,
  .r2-c3 {
    width: 595 / @Width;
    height: 410 / @Width;
  }
}

.border-wrap {
  .flex {
    display: flex;
  }
  .grow {
    flex-grow: 1;
  }
  .border-top {
    .border-top-left,
    .border-top-right {
      width: 50 / @Width;
      height: 50 / @Width;
      img {
        width: 50 / @Width;
        height: 50 / @Width;
      }
    }
    .border-top {
      height: 50 / @Width;
      img {
        width: 100%;
        height: 50 / @Width;
      }
    }
    .border-title {
      width: 205 / @Width;
      height: 50 / @Width;
      background-image: url("../assets/border/border-title.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      .border-name {
        color: #00e4ff;
        font-size: 18 / @Width;
        font-weight: 700;
        text-align: center;
        margin-top: 8 / @Width;
      }
    }
  }

  .border-body {
    .border-left,
    .border-right {
      width: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 100%;
      }
    }
    .border-body-middle {
      background-image: url("../assets/border/border-body-bg.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }

  .border-bottom {
    .border-bottom-left,
    .border-bottom-right {
      width: 25 / @Width;
      height: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 25 / @Width;
      }
    }
    .border-bottom {
      height: 25 / @Width;
      img {
        width: 100%;
        height: 25 / @Width;
      }
    }
    .border-bottom-middle {
      width: 258 / @Width;
      height: 25 / @Width;
      img {
        width: 258 / @Width;
        height: 25 / @Width;
      }
    }
  }
}
</style>

<script>
export default {
  name: "Person",
  data() {
    return {
      realData: null,
      unitData: null,
      workData: null,
      ageData: null,
      typeData: null,
      echarts: {
        echartsPersonR1C1: null,
        echartsPersonR2C1: null,
        echartsPersonR2C2: null,
        echartsPersonR2C3: null,
      },
    };
  },
  computed: {
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
  },
  watch: {
    currentProject(newVal, oldVal) {
      console.log("watch currentProject=>", newVal);
      this.onGetRealData(); // 获取实时数据
      this.onGetUnitData(); // 获取单位人数数据
      this.onGetWorkData(); // 获取工种分布数据
      this.onGetAgeData(); // 获取年龄分布数据
      this.onGetTypeData(); // 获取类型分布数据
    },
  },
  mounted() {
    this.onGetRealData(); // 获取实时数据
    this.onGetUnitData(); // 获取单位人数数据
    this.onGetWorkData(); // 获取工种分布数据
    this.onGetAgeData(); // 获取年龄分布数据
    this.onGetTypeData(); // 获取类型分布数据
  },
  methods: {
    // 获取实时数据
    onGetRealData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/wgconsumer/enterTheArenaOutDate`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.realData = res.data;
          that.echartsPersonR1C1Init();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取单位人数数据
    onGetUnitData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/wgconsumer/unitNumberStatistics`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.unitData = res.data;
          // that.echartsPersonR1C2Init();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取工种分布数据
    onGetWorkData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/wgconsumer/PercentageOfPopulation`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.workData = res.data;
          that.echartsPersonR2C1Init();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取年龄分布数据
    onGetAgeData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/wgconsumer/ageNumberorPercent`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.ageData = res.data;
          that.echartsPersonR2C2Init();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取类型分布数据
    onGetTypeData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/wgconsumer/administeOrLabour`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.typeData = res.data;
          that.echartsPersonR2C3Init();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    echartsPersonR1C1Init: function () {
      this.echarts.echartsPersonR1C1 = this.$echarts.init(
        document.getElementById("echarts-person-r1-c1")
      );
      var option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0, 255, 233,0)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255, 255, 255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0, 255, 233,0)",
                  },
                ],
                global: false,
              },
            },
          },
        },
        grid: {
          top: "15%",
          left: "5%",
          right: "5%",
          bottom: "15%",
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            splitArea: {
              // show: true,
              color: "#f00",
              lineStyle: {
                color: "#f00",
              },
            },
            axisLabel: {
              color: "#00e4ff",
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: this.realData.days,
          },
        ],

        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
              margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "进场人数",
            type: "line",
            smooth: true, //是否平滑
            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 8,
            lineStyle: {
              normal: {
                color: "#00b3f4",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#00b3f4",
              },
            },
            itemStyle: {
              color: "#00b3f4",
              borderColor: "#fff",
              borderWidth: 2,
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 2,
              shadowOffsetX: 2,
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0,179,244,0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,179,244,0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0,179,244, 0.9)",
                shadowBlur: 20,
              },
            },
            data: this.realData.counts,
          },
        ],
      };
      this.echarts.echartsPersonR1C1.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.echartsPersonR1C1.resize();
      });
    },
    

    echartsPersonR2C1Init: function () {
      this.echarts.echartsPersonR2C1 = this.$echarts.init(
        document.getElementById("echarts-person-r2-c1")
      );
      var listData = this.workData;
      var data = [];
      var legend = [];
      listData.forEach((item, index) => {
        data.push({
          name: item.job,
          value: item.totals,
        });
        legend.push(item.job);
      });
      var option = {
        legend: {
          data: legend,
          orient: "vertical",
          bottom: 0,
          right: 0,
          textStyle: {
            color: "#00e4ff",
          },
          itemWidth: 7,
          itemHeight: 7,
          formatter: function (name) {
            var arr;
            listData.forEach((item, index) => {
              if (name == item.job) {
                arr = item.job + " " + item.totals + "人 " + item.percents;
              }
            });
            return arr;
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        series: [
          {
            name: "总人数按工种分布",
            type: "pie",
            center: ["35%", "50%"],
            radius: ["40%", "70%"],
            label: {
              formatter: "{d}%",
              color: "#00e4ff",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: data,
          },
        ],
      };
      this.echarts.echartsPersonR2C1.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.echartsPersonR2C1.resize();
      });
    },
    echartsPersonR2C2Init: function () {
      this.echarts.echartsPersonR2C2 = this.$echarts.init(
        document.getElementById("echarts-person-r2-c2")
      );
      var listData = this.ageData;
      var yData = [];
      var xData = [];
      listData.forEach((item, index) => {
        yData.push(item.agePracket);
        xData.push(item.number);
      });
      var colorlist = [
        "#ff507d",
        "#8972ff",
        "#358bff",
        "#2df6e6",
        "#51ffa8",
        "#fffe6d",
        "#ffc853",
        "#fd8453",
      ];
      var option = {
        grid: {
          top: "5%",
          left: "15%",
          right: "5%",
          bottom: "5%",
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        xAxis: {
          type: "value",
          show: false,
          min: 0,
          max: 100,
        },
        yAxis: {
          type: "category",
          data: yData,
          axisLabel: {
            color: "#00e4ff",
          },
        },
        series: [
          {
            name: "总人数按年龄分布",
            data: xData,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(255, 255, 255, 0.1)",
              borderRadius: [0, 50, 50, 0],
            },
            itemStyle: {
              color: function (params) {
                return colorlist[params.dataIndex];
              },
              borderRadius: [0, 50, 50, 0],
            },
          },
        ],
      };
      this.echarts.echartsPersonR2C2.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.echartsPersonR2C2.resize();
      });
    },
    echartsPersonR2C3Init: function () {
      this.echarts.echartsPersonR2C3 = this.$echarts.init(
        document.getElementById("echarts-person-r2-c3")
      );
      var data = [
        { value: this.typeData.LabourNumber, name: "劳工" },
        { value: this.typeData.administeNumber, name: "项目管理" },
      ];
      var option = {
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          bottom: 0,
          itemWidth: 7,
          itemHeight: 7,
          textStyle: {
            color: "#00e4ff",
          },
          formatter: function (name) {
            var arr;
            data.forEach((item, index) => {
              if (name == item.name) {
                arr = item.name + " " + item.value + "人";
              }
            });
            return arr;
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        series: [
          {
            name: "总人数按类型分布",
            type: "pie",
            radius: ["50%", "90%"],
            center: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              formatter: "{b}\n{d}%",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "20",
                color: "#00e4ff",
                fontWeight: "bold",
                shadowColor: "#ffffff",
                shadowBlur: "5",
              },
            },
            labelLine: {
              show: false,
            },
            data: data,
          },
        ],
      };
      this.echarts.echartsPersonR2C3.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.echartsPersonR2C3.resize();
      });
    },
  },
};
</script>